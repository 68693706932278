import { IMarkQuery } from "../models/degree/query";
import { ICreateMark, IUpdateMark } from "../models/degree/request";
import { IMark, IMarkDetails } from "../models/degree/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class MarkService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStudentsClasstMarks = (query: IMarkQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IMark>>({
      path: `/student-mark`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getMarks = (query: IMarkQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<any>>({
      path: `/degrees`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getMark = (id: number, params?: RequestParams) =>
    this.http.request<IMarkDetails>({
      path: `/degrees/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createUpdateMark = (data: ICreateMark, params?: RequestParams) =>
    this.http.request<IMark>({
      path: "/degrees/update-or-store",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  createMark = (data: ICreateMark, params?: RequestParams) =>
    this.http.request<IMark>({
      path: "/degrees",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateMark = (id: number, data: IUpdateMark, params?: RequestParams) =>
    this.http.request<IMark>({
      path: `/degrees/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  updateApprovalStudentMark = (id: number, data: any, params?: RequestParams) =>
    this.http.request<any>({
      path: `/supervisor-panel/degrees/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      ...params,
    });

  deleteMark = (id: number, params?: RequestParams) =>
    this.http.request<IMark>({
      path: `/degrees/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default MarkService;
