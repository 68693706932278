import { IGradesQuery } from "../models/grades/query";
import { IGuardiansQuery } from "../models/guardians/query";
import { ICreateGuardian, IUpdateGuardian } from "../models/guardians/request";
import { IGetGuardiansResponse, IGuardian } from "../models/guardians/response";
import { HttpClient, RequestParams } from "./http-client";

export class GuardiansService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  // Get all guardians
  getAllGuardians = (query: IGradesQuery, params?: RequestParams) =>
    this.http.request<IGetGuardiansResponse>({
      path: "/guardians",
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  // Get a specific guardian
  getGuardian = (id: number, params?: RequestParams) =>
    this.http.request<IGuardian>({
      path: `/guardians/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  // Create a guardian
  createGuardian = (data: ICreateGuardian, params?: RequestParams) =>
    this.http.request<IGuardian>({
      path: "/guardians",
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });

  // Update a guardian
  updateGuardian = (
    id: number,
    data: IUpdateGuardian,
    params?: RequestParams
  ) =>
    this.http.request<IGuardian>({
      path: `/guardians/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  // Delete a guardian
  deleteGuardian = (id: number, params?: RequestParams) =>
    this.http.request<void>({
      path: `/guardians/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default GuardiansService;
