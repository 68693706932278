import { IUpdateSetting } from "../models/setting/request";
import { ISetting } from "../models/setting/response";
import { HttpClient, RequestParams } from "./http-client";

export class SettingService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getSetting = (id: number, params?: RequestParams) =>
    this.http.request<ISetting>({
      path: `/school-setting/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  updateSetting = (id: number, data: IUpdateSetting, params?: RequestParams) =>
    this.http.request<ISetting>({
      path: `/school-setting/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });
}

export default SettingService;
