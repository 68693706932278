import { IStudentQuery } from "../models/students/query";
import { ICreateStudent, IUpdateStudent } from "../models/students/request";
import { IStudent, IStudentDetails } from "../models/students/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class StudentsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStudents = (query: IStudentQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IStudent>>({
      path: `/students`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getAllStudentsClassroom = (
    classroomIId?: number,
    query?: IStudentQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IStudent>>({
      path: `/students-in-classroom/${classroomIId}`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getAllStudentsInfo = (query: any, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<any>>({
      path: `/student-info`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getStudent = (id: number, params?: RequestParams) =>
    this.http.request<IStudentDetails>({
      path: `/students/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createStudent = (data: ICreateStudent, params?: RequestParams) =>
    this.http.request<IStudent>({
      path: "/students",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateStudent = (id: number, data: IUpdateStudent, params?: RequestParams) =>
    this.http.request<IStudent>({
      path: `/students/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteStudent = (id: number, params?: RequestParams) =>
    this.http.request<IStudent>({
      path: `/students/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default StudentsService;
