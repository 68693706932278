import { IUserQuery } from "../models/users/query";
import { IUpdateUser } from "../models/users/request";
import { IUser, IUserDetails } from "../models/users/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class TimeTableService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllTimeTables = (query: any, params?: RequestParams) =>
    this.http.request<any[]>({
      path: `/session-timetable`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getSuperTimeTables = (query: any, params?: RequestParams) =>
    this.http.request<any[]>({
      path: `/supervisor-panel/session-timetable`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });
}

export default TimeTableService;
