import { IAcademicLevelQuery } from "../models/academicLevels/query";
import {
  ICreateactualClasses,
  IUpdateactualClasses,
} from "../models/actualClass/request";
import {
  IactualClasses,
  IactualClassesDetails,
} from "../models/actualClass/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class ActualClassesService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllActualClasses = (query: IAcademicLevelQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IactualClasses>>({
      path: `/actual-class`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getTeachearActualClasses = (
    query: IAcademicLevelQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IactualClasses>>({
      path: `/teacher-panel/actual-class`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getActualClasse = (id: number, params?: RequestParams) =>
    this.http.request<IactualClassesDetails>({
      path: `/actual-class/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createActualClasse = (data: ICreateactualClasses, params?: RequestParams) =>
    this.http.request<IactualClasses>({
      path: "/actual-class",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateActualClasse = (
    id: number,
    data: IUpdateactualClasses,
    params?: RequestParams
  ) =>
    this.http.request<IactualClasses>({
      path: `/actual-class/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteActualClasse = (id: number, params?: RequestParams) =>
    this.http.request<IactualClasses>({
      path: `/actual-class/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default ActualClassesService;
