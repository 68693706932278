import { IClassroomSubjectQuery } from "../models/classroomSubject/query";
import {
  ICreateClassroomSubject,
  IUpdateClassroomSubject,
} from "../models/classroomSubject/request";
import {
  IClassroomSubject,
  IClassroomSubjectDetails,
} from "../models/classroomSubject/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class ClassroomSubjectsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClassroomSubjects = (
    query: IClassroomSubjectQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IClassroomSubject>>({
      path: `/subject-classroom`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getAllTeachearClassroomSubjects = (
    query: IClassroomSubjectQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IClassroomSubject>>({
      path: `/teacher-panel/subject-classroom`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getClassroomSubject = (id: number, params?: RequestParams) =>
    this.http.request<IClassroomSubjectDetails>({
      path: `/subject-classroom/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createClassroomSubject = (
    data: ICreateClassroomSubject,
    params?: RequestParams
  ) =>
    this.http.request<IClassroomSubject>({
      path: "/subject-classroom",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateClassroomSubject = (
    id: number,
    data: IUpdateClassroomSubject,
    params?: RequestParams
  ) =>
    this.http.request<IClassroomSubject>({
      path: `/subject-classroom/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteClassroomSubject = (id: number, params?: RequestParams) =>
    this.http.request<IClassroomSubject>({
      path: `/subject-classroom/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default ClassroomSubjectsService;
